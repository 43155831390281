<template>
    <div class="body">
        <!-- 申请售后 -->
        <!--导航栏--->
        <section class="top">
            <van-nav-bar title="售后详情" safe-area-inset-top left-arrow :fixed='isTop' @click-left="onClickLeft" />
        </section>
        <!-- 商品信息 -->
        <section class="shopInfo">
            <div class="goodsInfo">
                <div class="goodsHeader">
                    <span>售后商品</span>
                </div>
                <div class="goodsList">
                    <div>
                        <van-image width="100" height="100" fit="fill" :src="goodsInfo.picUrl" />
                    </div>
                    <div class='goodsText'>
                        <div class='goodsPrice'>
                            <p>{{ goodsInfo.spuName || '--' }}</p>
                            <span>￥{{ goodsInfo.salesPrice || '--' }}{{ goodsInfo.needRice ? '+' + goodsInfo.needRice + '驿马'
                                : '' }}</span>
                        </div>
                        <div class="size fontColor">
                            <span>规格：{{ goodsInfo.specInfo || '...' }}</span>
                            <span>x{{ goodsInfo.quantity || '--' }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="selectClass">
            <p class="selectText">选择服务类型</p>
            <div class="selectClass-one" @click="selectService(0)">
                <div class="selectClass-one-left">
                    <p class="selectClass-one-left-p">我要退款（无需退货）</p>
                    <span class="selectClass-one-left-span">未/已收到货，或与商家协商一致不用退货只退款</span>
                </div>
                <div>
                    <span>
                        <van-icon name="arrow" size="mini" />
                    </span>
                </div>
            </div>
            <div class="selectClass-one" @click="selectService(1)">
                <div class="selectClass-one-left">
                    <p class="selectClass-one-left-p">我要退货退款</p>
                    <span class="selectClass-one-left-span">已收到货，需退还收到的货物</span>
                </div>
                <div>
                    <span>
                        <van-icon name="arrow" size="mini" />
                    </span>
                </div>
            </div>
            <div class="selectClass-one" @click="selectService(2)" v-if="!goodsInfo.tradeId">
                <div class="selectClass-one-left">
                    <p class="selectClass-one-left-p">我要换货</p>
                    <span class="selectClass-one-left-span">已收到货，需要换货</span>
                </div>
                <div>
                    <span>
                        <van-icon name="arrow" size="mini" />
                    </span>
                </div>
            </div>

        </section>
        <van-dialog v-model="isShowWin" title="请确认收货,再申请售后" show-cancel-button confirmButtonColor="#E54320"
            theme="round-button" @confirm="asConfirmGet">
        </van-dialog>
    </div>
</template>

<script>
import { getServiceIdDetail, checkOrderStatus } from '@/api/goodsService/index.js'
import { confirmGetGoodsYD } from "@/api/yundian/orderIdDetail/index.js";//侠客集市确认收货
import { confirmGetGoodsFL } from "@/api/orderIdDetail/index.js";
import { Toast } from 'vant';

export default {
    data() {
        return {
            isTop: true,
            isShowWin: false,//展示确认收货窗口
            goodsInfo: {},//商品信息
            status: '',
            timer: null,//定时器
            shopType: ''//区分订单是红尘坊的还是侠客集市的
        }
    },
    beforeMount() {
        //  this.getPage()
        // console.log('进来了？');
        this.status = this.$route.query.status
        this.shopType = this.$route.query.shopType
    },
    //前置守卫，判断前一个路由
    /*  beforeRouteEnter(to, from, next) {
         next(vm => {
             //  这里的vm指的就是vue实例，可以用来当做this使用
             vm.fromPath = from.path  //获取上一级路由的路径
             vm.beforeRoute = from.path
             // console.log(vm.fromPath, 'fromPath')
             }
         })
     }, */
    mounted() {
        let screenWidth = document.documentElement.getBoundingClientRect().width;
        document.documentElement.style.fontSize = screenWidth / 10 + "px";
        this.getPage()
    },
    methods: {
        //选择退款0/换货2/退货退款1
        selectService(code) {
            // console.log(code, 'code');
            if (code == 0 && this.status == '2') { //未收货申请退款
                this.$router.push({
                    path: "/returnMoneyOrGoods",
                    query: { code: code, itemId: this.$route.query.itemId, shopType: this.shopType },
                });

            } else if (code == 0 && this.status == '3') { //已收货申请退款
                // Toast('待收货状态下才可申请退款')
                checkOrderStatus({ ItemId: this.$route.query.itemId }).then(res => {
                    if (res.data.code == 0) {
                        if (res.data.data.status == '0') {
                            this.$router.push({
                                path: "/returnMoneyOrGoods",
                                query: { code: code, itemId: this.$route.query.itemId, shopType: this.shopType },
                            });
                        } else {
                            Toast({
                                duration: 2000, // 持续展示 toast
                                forbidClick: true,
                                message: '请先到设置绑定银行卡再申请',
                            })
                        }
                    } else {
                        Toast(res.data.message)
                    }
                }).catch(() => {
                    Toast('查询失败 请退出重试')
                })
            } else if (code == 1 && this.status == '2') { //未收货申请退货退款
                // this.isShowWin = true//先确认收货
                Toast('请确认收货后再申请')
                return
            } else if (code == 1 && this.status == '3') { //已收货申请退货退款
                checkOrderStatus({ ItemId: this.$route.query.itemId }).then(res => {
                    if (res.data.code == 0) {
                        if (res.data.data.status == '0') {
                            this.$router.push({
                                path: "/returnMoneyOrGoods",
                                query: { code: code, itemId: this.$route.query.itemId, shopType: this.shopType },
                            });
                        } else {
                            Toast({
                                duration: 2000, // 持续展示 toast
                                forbidClick: true,
                                message: '请先到设置绑定银行卡再申请',
                            })
                        }
                    } else {
                        Toast(res.data.message)
                    }
                }).catch(() => {
                    Toast('查询失败 请退出重试')
                })
                this.$router.push({
                    path: "/returnMoneyOrGoods",
                    query: { code: code, itemId: this.$route.query.itemId, shopType: this.shopType },
                });
            } else if (code == 2 && this.status == '3') { //已收货申请换货
                //跳转到换货页面侠客集市/红尘坊
                if (this.shopType == '3') {
                    // console.log('侠客集市');
                    this.$router.push({
                        path: "/yundian/applyForChange",
                        query: { data: JSON.stringify(this.goodsInfo) },
                    });

                } else if (this.shopType == '0') {
                    // console.log('红尘坊');
                    this.$router.push({
                        path: "/applyForChange",
                        query: { data: JSON.stringify(this.goodsInfo) },
                    });
                }
            } else if (code == 2 && this.status == '2') {//未收货申请换货
                // this.isShowWin = true//先确认收货
                Toast('请确认收货后再申请')
                return
            }
        },
        //获取页面信息
        getPage() {
            if (this.$route.query.itemId) {
                let itemId = this.$route.query.itemId
                // this.$store.commit('setSession', this.$route.query.session)
                getServiceIdDetail({ itemId, types: '1' }).then(res => {
                    if (res.data.code == 0) {
                        console.log(res.data.data, '<===信息');
                        this.goodsInfo = res.data.data
                    }
                })
            }
        },
        //点击返回
        onClickLeft() {
            this.$router.back(-1)
        },
        //确认收货
        asConfirmGet() {
            this.confirmGet();
        },
        confirmGet() {
            //发确认收货请求  区分侠客集市和红尘坊
            // Toast(this.shopType)
            if (this.shopType == '3') {
                confirmGetGoodsYD({ orderId: this.goodsInfo.orderId })
                    .then((res) => {
                        if (res.data.code == 0) {
                            Toast.success("确认成功");
                            this.onClickLeft()
                        } else {
                            Toast(res.data.message);
                        }
                        /*  this.timer2 = setTimeout(() => {
                             this.$router.push({
                                 path: "/yundian/confirmReceipt",
                                 query: { data: res.data.data },
                             });
                         }, 500); */
                    })
                    .catch((err) => {
                        Toast.fail("请刷新重试", err);
                    });
            } else if (this.shopType == '0') {
                confirmGetGoodsFL({ orderId: this.goodsInfo.orderId })
                    .then((res) => {
                        if (res.data.code == 0) {
                            Toast.success("确认成功");
                            this.timer2 = setTimeout(() => {
                                this.onClickLeft()
                            }, 500);
                        } else {
                            Toast(res.data.message);
                        }
                    })
                    .catch((err) => {
                        Toast("请刷新重试", err);
                    });
            }
        },
    },
}
</script>

<style lang="less" scoped>
.body {
    width: 100%;
    height: 100%;
    background: #f7f7f7;

    .fontColor {
        line-height: 0.7784rem;
        color: #666666;
        font-size: 12px;
    }

    section {
        background: #fff;
        padding: 0.4324rem 0.5189rem;
    }

    .top {
        height: 4%;
        // border-bottom: 1px solid #E5E5E5;
        font-size: 1.2973rem;
    }

    .shopInfo {
        display: flex;
        flex-flow: column nowrap;

        .goodsInfo {
            width: 100%;

            .goodsHeader {
                margin-bottom: 10px;
                font-size: 16px;
            }

            .goodsList {
                display: flex;
                flex-flow: row nowrap;
                align-items: center;

                .goodsText {
                    flex: 1;
                    height: 100px;
                    padding-left: 5px;
                    display: flex;
                    flex-flow: column nowrap;
                    justify-content: space-between;

                    .goodsPrice {
                        display: flex;
                        flex-flow: row nowrap;
                        justify-content: space-between;
                    }

                    p {
                        // width: 6.4865rem;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }

                    .size {
                        display: flex;
                        justify-content: space-between;
                    }
                }
            }

            /* .evaluateDetail {
                background: #F7F7F7;
                // padding: 0.2rem;

                .van-field {
                    background: #F7F7F7;
                }

                .van-uploader {
                    height: 6rem;
                    margin-left: 0.4rem;
                    margin-top: 0.4rem;

                    /deep/.van-uploader__upload {
                        border: 1px dashed #999999;
                        margin: 1px;
                    }

                }

            } */
        }

    }

    .selectClass {
        .selectText {
            color: #999999;
            font-size: 14px;
            margin-bottom: 0.2703rem;
        }

        .selectClass-one {
            padding: 0.48rem 0;
            border-bottom: 1px solid #E5E5E5;
            background: #ffffff;
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: center;

            .selectClass-one-left {
                .selectClass-one-left-p {
                    font-size: 14px;
                }

                .selectClass-one-left-span {
                    font-size: 12px;
                    color: #999999;
                }
            }
        }
    }

}
</style>